import Head from "next/head";
import { useRouter } from "next/router";

const PageHead = ({ title, description }) => {
  const { asPath } = useRouter();

  const canonicalUrl = `https://contact.eg${asPath}`;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
    </Head>
  );
};

export default PageHead;
